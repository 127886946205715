import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticateEmail } from '../api/cocktaildb';
import { Form, Button, Container } from 'react-bootstrap';

export default function EnterEmail() {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const history = useNavigate();

  const isValidEmail = (mail) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(mail);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await authenticateEmail(email);
    history.push('/confirmemail');
  };

  return (
    <Container className='form'>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail" className='formGroup'>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            isValid={isValidEmail(email)}
            isInvalid={!!email && !isValidEmail(email)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid email.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formConfirmEmail" className='formGroup'>
          <Form.Label>Confirm Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Confirm email"
            value={confirmEmail}
            onChange={e => setConfirmEmail(e.target.value)}
            isValid={isValidEmail(confirmEmail) && email === confirmEmail}
            isInvalid={!!confirmEmail && (!isValidEmail(confirmEmail) || email !== confirmEmail)}
          />
          <Form.Control.Feedback type="invalid">
            Emails do not match or it's invalid.
          </Form.Control.Feedback>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          disabled={!(email === confirmEmail && isValidEmail(email) && isValidEmail(confirmEmail))}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
}