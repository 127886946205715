import { forwardRef, useEffect, useRef, useState, useCallback } from "react";
import { addHistory } from "../api/cocktaildb";
import { asPercentage, isNullOrNaN } from "../api/utility";
import Login from "./login";
import { useAuth } from "./authprovider";

const pointsForAccuracy = 3;
const pointsForSpeed = 2;
const speedLimitMilliseconds = 2000;

class Score {
  #questions = [];

  constructor(questions = []) {
    this.#questions = questions;
  }

  set questions(questions) {
    this.#questions = questions;
  }

  get questions() {
    return this.#questions;
  }

  get totalQuestions() {
    return this.#questions.length;
  }

  get points() {
    let accuracyPoints = 0;
    let speedPoints = 0;
    let totalPoints = 0;
    let numCorrect = 0;
    let percentagePoints = 0;
    let speedPercentagePoints = 0;

    this.#questions.forEach((question, questionIndex) => {
      accuracyPoints += question.result ? pointsForAccuracy : 0;
      speedPoints += question.speedBonus ? pointsForSpeed : 0;
      numCorrect += question.result ? 1 : 0;
    });
    totalPoints = accuracyPoints + speedPoints;
    percentagePoints = numCorrect / this.totalQuestions;
    speedPercentagePoints = (speedPoints / pointsForSpeed) / this.totalQuestions;

    return {
      totalPoints: totalPoints,
      speedPoints: speedPoints,
      accuracyPoints: accuracyPoints,
      numCorrect: numCorrect,
      totalQuestions: this.totalQuestions,
      percentagePoints: percentagePoints,
      speedPercentagePoints: speedPercentagePoints
    };
  }

}

function Question({ question }) {
  let rowFactor = question.rowFactor;
  let colFactor = question.colFactor;

  return (
    <>
      <div className="spacer">
      </div>
      <div className="pairRow">
        <div className="pairElement">{rowFactor}</div>
        <div className="pairElement pairNonElement">x</div>
        <div className="pairElement">{colFactor}</div>
      </div>
    </>
  );
}

const Response = forwardRef(function Response(props, ref) {
  const { checkAnswer } = props;

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      const inputResponse = document.getElementById("inputResponse");
      checkAnswer(inputResponse.value);
    }
  }

  return (
    <input ref={ref} type="number" className="response" onKeyDown={handleKeyDown} id="inputResponse" autoFocus></input>
  );
});

function Game({ question, checkAnswer, responseInput }) {
  return (
    <>
      <Question question={question} />
      <Response checkAnswer={checkAnswer} ref={responseInput} />
    </>
  );
}

function ScoreBoard({ score, quizQuestions }) {
  const points = score.points;

  return (
    <>
      <div className="scoreBoard">
        <div className="pointCounter">{points.totalPoints}</div>
        <div>points</div>
        <div className="spacerSmall" />
        <div className="statsContainer">
          <div className="statsRow">
            <div className="statsRowElement statsRowHeader" id="accuracy">🎯</div>
            <div className="statsRowElement statsRowHeader" id="speed">⚡</div>
            <div className="statsRowElement statsRowHeader">⏳</div>
          </div>
        </div>
        <div className="statsContainer">
          <div className="statsRow">
            <div className="statsRowElement statsRowValue">{isNullOrNaN(points.accuracyPoints) ? 0 : points.accuracyPoints} ({asPercentage(isNullOrNaN(points.percentagePoints) ? 0 : points.percentagePoints)})</div>
            <div className="statsRowElement statsRowValue">{isNullOrNaN(points.speedPoints) ? 0 : points.speedPoints} ({asPercentage(isNullOrNaN(points.speedPercentagePoints) ? 0 : points.speedPercentagePoints)})</div>
            <div className="statsRowElement statsRowValue">{quizQuestions ? quizQuestions.length - points.totalQuestions : 0}</div>
          </div>
        </div>
      </div>
    </>
  );
}

function Rules() {
  return (
    <>
      <div>
        Accuracy: {pointsForAccuracy} points per correct answer
      </div>
      <div>
        Speed: {pointsForSpeed} points per fast (&lt;{speedLimitMilliseconds / 1000}s) correct answer
      </div>
    </>
  );
}

function Introduction({ handleStartQuizClick }) {
  return (
    <>
      <div className="spacer" />
      <Rules />
      <div className="quizIntro">
        <input type="button" value="Start Quiz" onClick={handleStartQuizClick}></input>
      </div>
    </>
  );
}

function Conclusion({ handleStartQuizClick, loginToSave }) {
  return (
    <>
      <div className="conclusionDiv">
        {loginToSave && <Login headerText="Login to Save" showAsSubComponent={true} />}
        <input type="button" value="Quiz Me Again" onClick={handleStartQuizClick}></input>
      </div>
    </>
  );
}

function Overlay({ text }) {
  const className = ["overlay", text === -1 ? "noShow" : "showBlock"].join(" ");
  return (
    <>
      <div className={className}>
        <div className="overlayText">
          {text}
        </div>
      </div>
    </>
  );
}

export default function Quiz() {
  const [phase, setPhase] = useState({ name: "before" });
  const [quizQuestions, setQuizQuestions] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(-1);
  const [question, setQuestion] = useState(null);
  const [score, setScore] = useState(new Score());
  const [countdown, setCountdown] = useState(-1);
  const [quizStarted, setQuizStarted] = useState(false);
  const [loginToSave, setLoginToSave] = useState(false);
  const responseInput = useRef(null);
  const scoreRef = useRef(score);
  const phaseRef = useRef(phase);
  const quizQuestionsRef = useRef(quizQuestions);
  const { authToken, isAuthenticated } = useAuth();

  function flashElement(id) {
    let element = document.getElementById(id);
    element.classList.add('flash');
    setTimeout(function () {
      element.classList.remove('flash');
    }, 1000);
  }

  const checkAnswer = async (response) => {
    let endTime = Date.now();
    question.endTime = endTime;
    question.result = question.product === Number(response);
    question.speedBonus = question.product === Number(response) && endTime - question.startTime < speedLimitMilliseconds;

    if (question.result === true) {
      flashElement('accuracy');
    }

    if (question.speedBonus === true) {
      flashElement('speed');
    }

    const newScore = new Score(score.questions.slice());
    newScore.questions.push(question);
    setScore(newScore);

    if (questionIndex + 1 < quizQuestions.length) {
      responseInput.current.value = "";
      setQuestionIndex(questionIndex + 1);
    } else {
      setPhase({ name: "after" });
      setQuizStarted(false);
    }
  }

  const saveHistory = async (score) => {
    try {
      const resultJson = JSON.stringify(score.points);
      console.log("adding score to history:", resultJson);
      await addHistory(resultJson);
      // TODO: add visual notification of score being added to history
    } catch (error) {
      console.log(error)
    }
  }

  function handleStartQuizClick() {
    console.log("starting in 5s");
    setQuizStarted(true);
    setCountdown(5);
  }

  function shuffleArray(array) {
    let shuffled = array.slice(); // Create a copy of the original array to avoid modifying it directly
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
    }
    return shuffled;
  }

  const getQuizQuestions = useCallback(
    (startFactor, endFactor, maxFactor) => {
      function getQuestion(row, col) {
        return { rowFactor: row, colFactor: col, product: row * col, result: null, speedBonus: null, startTime: null, endTime: null };
      }

      const numQuestions = ((((endFactor - startFactor + 1) * (maxFactor - startFactor + 1)) - (endFactor - startFactor + 1)) / 2) + (endFactor - startFactor + 1);
      let questions = Array.from({ length: numQuestions });
      let index = 0;
      for (let row = startFactor; row <= endFactor; row++) {
        for (let col = row; col <= endFactor; col++) {
          questions[index] = getQuestion(row, col);
          index++;
        }
      }

      const shuffledQuestions = shuffleArray(questions);
      return shuffledQuestions;
    },
    []
  );

  // monitor score and update the ref (useState+useRef+useEfect pattern)
  useEffect(() => {
    console.log("useEffect for score");
    scoreRef.current = score;
  }, [score]);

  useEffect(() => {
    console.log("useEffect for phase");
    phaseRef.current = phase;
  }, [phase]);

  // monitor quizQuestions and update the ref
  useEffect(() => {
    console.log("useEffect for quizQuestions");
    quizQuestionsRef.current = quizQuestions;
  }, [quizQuestions]);

  // monitor questionIndex to update the question
  useEffect(() => {
    console.log("useEffect for questionIndex; questionIndex:", questionIndex);
    if (questionIndex >= 0) {
      const question = quizQuestionsRef.current[questionIndex];
      question.startTime = Date.now();
      setQuestion(question);
      setPhase({ name: "during" });
    } else {
      console.log("setting question null");
      setQuestion(null);
    }
  }, [questionIndex]);

  // If the quiz has started, countdown 1 second from the current countdown until it reaches 0, after which, start the quiz
  useEffect(() => {
    if (quizStarted) {
      if (countdown >= 0) {
        setTimeout(() => {
          setCountdown(countdown - 1);
        }, 1000);
      } else {
        console.log("start!");
        setScore(new Score());
        setQuizQuestions(getQuizQuestions(1, 12, 12));
        setQuestionIndex(0);
      }
    }
  }, [quizStarted, countdown, getQuizQuestions]);

  // If phase is "after", save the score
  useEffect(() => {
    const addHistoryAsync = async () => {
      try {
        if (await isAuthenticated()) {
          await saveHistory(scoreRef.current);
        } else {
          setLoginToSave(true);
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

    if (phase.name === "after") {
      addHistoryAsync();
    }
    // eslint-disable-next-line
  }, [phase]);

  // UseEffect on login status changes 
  // When an unauthenticated user finishes the quiz, they are asked to login to save results
  // This will change the authToken; if true, no need to show Login to Save and vice versa.
  useEffect(() => {
    const authTokenChangeAsync = async () => {
      if (authToken) {
        setLoginToSave(false);
        if (phaseRef.current.name === "after") {
          await saveHistory(scoreRef.current);
        }
      } else {
        setLoginToSave(true);
      }
    };

    authTokenChangeAsync();
  }, [authToken]);

  return (
    <>
      <Overlay text={countdown} />
      <h2>Quiz</h2>
      <ScoreBoard score={score} quizQuestions={quizQuestions} />
      {phase.name === "before" ?
        <>
          <Introduction handleStartQuizClick={handleStartQuizClick} />
        </> :
        phase.name === "after" ?
          <>
            <Conclusion handleStartQuizClick={handleStartQuizClick} loginToSave={loginToSave} />
          </> :
          <>
            <Game question={question} checkAnswer={checkAnswer} responseInput={responseInput} />
          </>}
    </>
  );
}