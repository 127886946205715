import React from 'react';
import { Card, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

export default function BaseLayer() {
  return (
    <div className="innerContent">
      <h2>The Base Layer: 1x, 10x, 2x, and 5x</h2>
      <h5 className='subtext'>
        Start with the perfect foundation
      </h5>

      <div className='spacer'></div>

      <p>
        The 1x, 10x, 2x, and 5x tables establish an intuitive foundation for your student to start their multiplication journey.
      </p>

      <section className="number-section">
        <h3>1x: Fundamental Intuition</h3>
        <p>
          Any number multiplied by 1 is the number. This is probably straightforward to you, but not every student grasps this immediately. Work patiently with them until it's intuitive, using the ideas for teaching 1x listed below. When your student understands the 1x, they'll have a fundamental understand of what multiplication.
        </p>
        <Container>
          <Row>
            <Col sm>
              <Card>
                <Card.Header>1x, Teaching Tip #1</Card.Header>
                <Card.Body>
                  <Card.Title>The Word "Of"</Card.Title>
                  <Card.Text>
                    Use "of" in place of the word "times." For example, instead of "1 times 12" you would say "1 of 12," which makes more sense to the student because 1 of anything is the thing. This might help the student as they progress to other numbers as well (e.g. "2 of 7" can help the student visualize 2 7s).
                  </Card.Text>
                  <Button variant="primary" href="../ofvisualization?table=1">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>1x, Teaching Tip #2</Card.Header>
                <Card.Body>
                  <Card.Title>Sub In An Object</Card.Title>
                  <Card.Text>
                    Include an everyday object. "What's 1 times (or of) apple? Apple." By substituting the number with an object, it drives home the point that 1 times anything (be it a number or an object) is the thing.
                  </Card.Text>
                  <Button variant="primary" href="../ofvisualization?table=1&object=apple">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="number-section">
        <h3>10x: Experiencing The Power Of Multiplication</h3>
        <p>
          Learning the 10x table is eye opening for the student. They intuitively appreciate the number 10 as being complete ("perfect 10") and symmetrical (5 on the left hand and 5 on the right hand give you 10). Even though 10 is not a large number, it's place as a counting milestone is cemented from a person's earliest days. And though the student might not realize this yet, their intuitive appreciation for the number 10 is matched by the number 10's role as the fundamental building block of the decimal number system. Unlocking the 10x table is the student's first experience with the power of multiplication.
        </p>
        <Container>
          <Row>
            <Col sm>
              <Card>
                <Card.Header>10x, Teaching Tip #1</Card.Header>
                <Card.Body>
                  <Card.Title>Just Add 0</Card.Title>
                  <Card.Text>
                    Show your student that multiplying any number by 10 is as simple as putting a zero next to the number. First, write a 10x problem, e.g. 10 x 3 = 30. Next, draw a box around the "10 x". Finally, draw an arrow from the "10 x" to the zero at the end of the product.
                    <div style={{ textAlign: 'center', marginTop: '15px' }}>
                      <img
                        src="/10s-JustAddZero.png"
                        alt="10s - Just Add 0"
                        width="300"
                      />
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>10x, Teaching Tip #2</Card.Header>
                <Card.Body>
                  <Card.Title>Visualize With A Number Line</Card.Title>
                  <Card.Text>
                    A number line provides a visual representation of the 10x table. First, draw a horizontal line with 12 equally spaced vertical markers. Next, number each vertical marker at the bottom of the marker. Finally, number each vertical marker at the top of the marker by 10x.
                    <div style={{ textAlign: 'center', marginTop: '15px' }}>
                      <img
                        src="/10s-NumberLineShort.png"
                        alt="10s - Number Line"
                        width="300"
                      />
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="number-section">
        <h3>2x: The Bridge From Addition To Multiplication</h3>
        <p>
          2 times any number is the same as the number added to itself. With this simple observation, we have identified a bridge for the student to cross from addition (a skill they've already mastered) to multiplication. As they practice the 2x table by translating the multiplication problem to an addition problem, call out their ability to do multiplication by reinterpreting it as an addition problem. They can carry this understanding of multiplication as a variation of addition into the other multiplication numbers.
        </p>
        <p>
          The 2x table is also a good moment to introduce memorization through repitition. A student that is proficient with addition can likely add a number to itself in their head fairly quickly and perhaps even intuitively, so as they practice 2x, their skills in addition are a supportive tool that can keep them from feeling discouraged and reassure them that they do in fact understand what it means to multiply numbers. And as their memorization improves, they will see that even though adding two numbers together is fast, recalling a multiplication problem from memory is even faster.
        </p>
        <Container>
          <Row>
            <Col sm>
              <Card>
                <Card.Header>2x, Teaching Tip #1</Card.Header>
                <Card.Body>
                  <Card.Title>A Number Added To Itself</Card.Title>
                  <Card.Text>
                    Show the student that 2 times any number is the number added to itself. For example, an equivalent of 2 x 3 is 3 + 3, which your student can more easily determine is 6.
                  </Card.Text>
                  <Button variant="primary" href="../decompose?table=2">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>2x, Teaching Tip #2</Card.Header>
                <Card.Body>
                  <Card.Title>Visualizing With Reflection</Card.Title>
                  <Card.Text>
                    Prompt the student to imagine sets of objects ranging in quantity (e.g. 2 apples, 3 toy trains, 7 stones). Have them visualize these sets of objects being set in front of a mirror. Help them see in their minds eye that the objects are doubling, which is the same as multiplying times 2.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>2x, Teaching Tip #3</Card.Header>
                <Card.Body>
                  <Card.Title>Counting Socks</Card.Title>
                  <Card.Text>
                    This tip looks at 2x multiplication from the opposite direction, as a combination of 2s rather than a combination of the number being multiplied by 2. Take out several pairs of socks and use them to demonstrate a 2x multiplication problem, e.g. 2 x 7 would be demonstrated by 7 pairs of socks.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      
      <section className="number-section">
        <h3>5x: Flexing The Multiplication Muscles</h3>
        <p>
          The 5x table are the first number in the L2X multiplication pyramid that don't have a nifty trick for quickly determining the answer like 1x and 10x, nor is it possible to do a quick addition translation on the fly like 2x. Having said that, your student can still approach 5x from a place of intuition. Use the tips and your own techniques to build the student's confidence in their understanding of 5x, and then work with them on memorization through repetition.
        </p>
        <Container>
          <Row>
            <Col sm>
              <Card>
                <Card.Header>5x, Teaching Tip #1</Card.Header>
                <Card.Body>
                  <Card.Title>Use Your Hands</Card.Title>
                  <Card.Text>
                    Since their earliest days, your student has known that there are 5 fingers on each of their hands. Have your student hold up one hand and ask them, how many fingers? Have them hold up the other hand next to the first hand and ask them, now how many fingers? Close both hands and then open up the first hand again, telling the student "15 fingers," then open the other hand and say "20 fingers." Keep repeating this, until you get up to 60 fingers. This will help them understand that the 5x multiplication table is at their finger tips.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>5x, Teaching Tip #2</Card.Header>
                <Card.Body>
                  <Card.Title>Combine "Of" With The Hand</Card.Title>
                  <Card.Text>
                    Create a special 5x multiplication table that shows the mathematical expression of a 5x problem (e.g. 3 x 5 = 15) next to a modified version of the problem that uses the word "of' and a picture, drawing, or emoji of a hand (e.g. 3 of [hand emoji] = 15). In this table, point out how the answers alternate from ending in 5 and 0, and that odd multipliers will end in 5 and even multipliers will end in 0.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>5x, Teaching Tip #3</Card.Header>
                <Card.Body>
                  <Card.Title>Half Of 10x</Card.Title>
                  <Card.Text>
                    Show the student the answer to any 5x multiplication is half of the same 10x multiplication. For example, 7 x 5 is equal to one half of 7 x 10. This type will resonate with students who have an intuitive sense for what "half" means.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <footer>
        <h3>Summary</h3>
        <p>
          By thoroughly understanding and mastering these foundational numbers, learners set themselves up for success as they climb the Multiplication Pyramid, layer by layer.
        </p>
      </footer>
    </div>);
}
