import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';

const validNumbers = [2, 3, 4, 6, 7, 9, 11];

const Decompose = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tableNumber, setTableNumber] = useState(parseInt(searchParams.get('table')) || 3); // Default to 3s table
  const [isValidTableNumber, setIsValidTableNumber] = useState(validNumbers.includes(tableNumber));

  const handleSelect = (eventKey) => {
    setTableNumber(parseInt(eventKey));
    setIsValidTableNumber(validNumbers.includes(parseInt(eventKey)));
    navigate(`/decompose?table=${eventKey}`);
  };

  const tableStyle = {
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  const boxStyle = {
    padding: '5px',
    margin: '5px',
    display: 'inline-block',
  };

  const iBoxStyle = {
    ...boxStyle,
    backgroundColor: '#FFD700', // Gold color for the number box
  };

  const partABBoxStyle = {
    ...boxStyle,
    backgroundColor: '#ADD8E6', // Light blue color for parts B and C box
    minHeight: '30px',
  };

  const renderPartA = (i) => {
    switch (tableNumber) {
      case 2:
        return <span style={partABBoxStyle}><span style={iBoxStyle}>{i}</span></span>;
      case 4:
        return <span style={partABBoxStyle}>2 x <span style={iBoxStyle}>{i}</span></span>;
      case 6:
        return <span style={partABBoxStyle}>5 x <span style={iBoxStyle}>{i}</span></span>;
      case 7:
        return <span style={partABBoxStyle}>5 x <span style={iBoxStyle}>{i}</span></span>;
      case 8:
        return <span style={partABBoxStyle}>5 x <span style={iBoxStyle}>{i}</span></span>;
      case 9:
        return <span style={partABBoxStyle}>10 x <span style={iBoxStyle}>{i}</span></span>;
      case 11:
        return <span style={partABBoxStyle}>10 x <span style={iBoxStyle}>{i}</span></span>;
      default:
        return <span style={partABBoxStyle}>2 x <span style={iBoxStyle}>{i}</span></span>;
    }
  };

  const renderPartB = (i) => {
    switch (tableNumber) {
      case 2:
        return <span style={partABBoxStyle}>+ <span style={iBoxStyle}>{i}</span></span>;
      case 4:
        return <span style={partABBoxStyle}>+ 2 x <span style={iBoxStyle}>{i}</span></span>;
      case 6:
        return <span style={partABBoxStyle}>+ <span style={iBoxStyle}>{i}</span></span>;
      case 7:
        return <span style={partABBoxStyle}>+ 2 x <span style={iBoxStyle}>{i}</span></span>;
      case 8:
        return <span style={partABBoxStyle}>+ 2 x <span style={iBoxStyle}>{i}</span></span>;
      case 9:
        return <span style={partABBoxStyle}>- <span style={iBoxStyle}>{i}</span></span>;
      case 11:
        return <span style={partABBoxStyle}>+ <span style={iBoxStyle}>{i}</span></span>;
      default:
        return <span style={partABBoxStyle}>+ <span style={iBoxStyle}>{i}</span></span>;
    }
  };

  const tableNumberDropdownItems = validNumbers.map((factor) => {
    return (
      <Dropdown.Item key={factor} eventKey={factor}>{factor}x</Dropdown.Item>
    );
  });

  const timesTableRows = [];
  for (let i = 1; i <= 12; i++) {
    timesTableRows.push(
      <tr key={i}>
        <td>{tableNumber} x <span style={iBoxStyle}>{i}</span></td>
        <td>=</td>
        <td>{renderPartA(i)}</td>
        <td>{renderPartB(i)}</td>
        <td>=</td>
        <td>{eval(`${tableNumber} * ${i}`)}</td>
      </tr>
    );
  }

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>The Deconstructed {tableNumber}x Table</h2>
      <h5 className='subtext'>Building from tables that your student has already learned</h5>
      <div className='spacer' />
      {isValidTableNumber ?
        <p>This table displays {tableNumber}x as a combination of 2 simpler factors.</p>
        : <p>
          Please select from the list below:
        </p>
      }
      <Dropdown onSelect={handleSelect}>
        <Dropdown.Toggle id="dropdown-basic">
          {isValidTableNumber ? `${tableNumber}x` : "--"}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {tableNumberDropdownItems}
        </Dropdown.Menu>
      </Dropdown>
      {isValidTableNumber ?
        <>
          <div className='spacer' />
          <table style={tableStyle}>
            <thead>
              <tr>
                <th>Question</th>
                <th></th>
                <th>Part A</th>
                <th>Part B</th>
                <th></th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              {timesTableRows}
            </tbody>
          </table>
        </> : null
      }
    </div>
  );
};

export default Decompose;
