// import { refresh } from "./cocktaildb";

/* export const getCurrentLogin = () => {
    const currentLoginJson = localStorage.getItem('currentLogin');
    let currentLogin = null;
    if (currentLoginJson) {
        currentLogin = JSON.parse(currentLoginJson);
    }
    return currentLogin;
}; */

/* export const storeCurrentLogin = (currentLogin) => {
    localStorage.setItem('currentLogin', JSON.stringify(currentLogin));
}; */

/* export const clearCurrentLogin = () => {
    localStorage.removeItem('currentLogin');
}; */

export const asPercentage = (decimalValue) => {
    const percentage = (decimalValue * 100).toFixed(0) + '%';
    return percentage;
};

export const isNullOrNaN = (value) => {
    return value === null || isNaN(value);
};

/*export const isAuthenticated = async () => {
    const currentLogin = getCurrentLogin();
    if(currentLogin) {
        try {
            await refresh();
            return true;
        } catch(error) {
            console.log("isAuthenticated()->refresh() failed:", error);
            return false;
        }
    } else {
        return false;
    }
};*/