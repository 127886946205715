import React from 'react';

import { Card, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

export default function ApexLayer() {
  return (
    <div className="innerContent">
      <h2>
        Mastering the Apex of the Multiplication Pyramid
      </h2>
      <p>
        The apex of the Multiplication Pyramid is comprised of the 11x and 12x tables. While these numbers may initially seem more complex, their mastery showcases the culmination of understanding and skill development throughout one's journey with multiplication. With the right strategies, these numbers can be as intuitive as the ones below them in the pyramid.
      </p>

      <section className="number-section">
        <h3>11x: Patterns and Quick Calculations</h3>
        <p>
          The 11x table offers both a simple decomposition as well as unique patterns that will great aid your student.
        </p>
        <p>
          After your student posseses confidence in their understanding of 11x multiplication, work with them on memorization through repetition.
        </p>
        <Container>
          <Row>
            <Col sm>
              <Card>
                <Card.Header>11x, Teaching Tip #1</Card.Header>
                <Card.Body>
                  <Card.Title>Visualize 11x As 10x + 1x</Card.Title>
                  <Card.Text>
                    A table showing each 11x multiplication problem broken down into 10x plus 1x can help strengthen this concept in your student's mind.
                  </Card.Text>
                  <Button variant="primary" href="../decompose?table=11">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>11x, Teaching Tip #2</Card.Header>
                <Card.Body>
                  <Card.Title>Unique Patterns</Card.Title>
                  <Card.Text as="div">
                    <p>For single-digit numbers, 11 x the number is the number twice. E.g., 11 * 8 = 88.</p>
                    <p>For double-digit numbers, add the two digits to get the number in the tens place. E.g., for 11 * 15, add 1 and 5 to get 6 as the tens place. Then the first number ("1") and last number ("5") in 15 becomes the first and last number of the answer: 165.</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>11x, Teaching Tip #3</Card.Header>
                <Card.Body>
                  <Card.Title>Memorization Through Repetition</Card.Title>
                  <Card.Text>
                    Once your student is confident with their understanding of 11x multiplication, work with them on repition until they memorize the 11x table.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="number-section">
        <h3>12x: The Top of the Pyramid</h3>
        <p>
          Your student has reached the top of the pyramid! With the confidence and knowledge that they've gained by this point in the L2X method, the 12x table will be a victory lap.
        </p>
        <p>
          After your student posseses confidence in their understanding of 12x multiplication, work with them on memorization through repetition.
        </p>
        <Container>
          <Row>
            <Col sm>
              <Card>
                <Card.Header>12x, Teaching Tip #1</Card.Header>
                <Card.Body>
                  <Card.Title>Visualize 12x As 10x + 2x</Card.Title>
                  <Card.Text>
                    A table showing each 12x multiplication problem broken down into 10x plus 2x can help strengthen this concept in your student's mind.
                  </Card.Text>
                  <Button variant="primary" href="../decompose?table=12">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>12x, Teaching Tip #2</Card.Header>
                <Card.Body>
                  <Card.Title>The Word "Of" With Objects</Card.Title>
                  <Card.Text>
                    Sub in the word "of" in place of "times" and accompany this with a diagram of simple objects.
                  </Card.Text>
                  <Button variant="primary" href="../ofvisualization?table=12">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>12x, Teaching Tip #3</Card.Header>
                <Card.Body>
                  <Card.Title>Memorization Through Repetition</Card.Title>
                  <Card.Text>
                    Once your student is confident with their understanding of 12x multiplication, work with them on repition until they memorize the 12x table.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <footer>
        <h3>Summary</h3>
        <p>
          Reaching and mastering the apex signifies the completion of the Multiplication Pyramid journey. With the patterns and strategies for 11x and 12x under one's belt, multiplication becomes a skill mastered and ready for application in diverse mathematical scenarios.
        </p>
      </footer>
    </div>);
}