export default function MultiplicationPair({selectedCell}) {
    let rowFactor = "0";
    let colFactor = "0";
    let result = "0";
  
    if (selectedCell.row != null && selectedCell.col != null) {
      rowFactor = selectedCell.row;
      colFactor = selectedCell.col;
      result = selectedCell.row * selectedCell.col;
    }
  
    return (
      <div className="pairRow">
        <div className="pairElement">{rowFactor}</div>
        <div className="pairElement pairNonElement">x</div>
        <div className="pairElement">{colFactor}</div>
        <div className="pairElement pairNonElement">=</div>
        <div className="pairElement">{result}</div>
      </div>
    );
  }