import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { useAuth } from "./authprovider";

export default function Dashboard() {
  const { authToken } = useAuth();

  return (
    <div className="innerContent">
      <h2>Your Dashboard</h2>
      <p>
        Hello, {authToken.login.email}!
      </p>

      <div className='spacer' />

      <Card>
        <Card.Header>START HERE</Card.Header>
        <Card.Body>
          <Card.Title>Overview of The Pyramid (1 min)</Card.Title>
          <Card.Text>
            The Pyramid is L2X's structured approach to mastering multiplication. This quick read provides a high-level understanding of the L2X journey.
          </Card.Text>
          <Button variant="primary" href="./thepyramid">Pyramid Overview</Button>
        </Card.Body>
      </Card>

      <div className='spacer' />

      <Card>
        <Card.Header>ASCEND THE PYRAMID</Card.Header>
        <Card.Body>
          <Card.Title>The Pyramid Levels</Card.Title>
          <Card.Text>
            Each of the Pyramid's levels serve as building blocks for subsequent levels. Navigate from here or the top menu.
          </Card.Text>
          <ListGroup variant="flush">
            <ListGroup.Item><a href="./lessons/baselayer">Base Layer</a></ListGroup.Item>
            <ListGroup.Item><a href="./lessons/baselayer">Second Layer</a></ListGroup.Item>
            <ListGroup.Item><a href="./lessons/baselayer">Third Layer</a></ListGroup.Item>
            <ListGroup.Item><a href="./lessons/baselayer">Apex</a></ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>

      <div className='spacer' />

      <Card>
        <Card.Header>COMING SOON</Card.Header>
        <Card.Body>
          <Card.Title>Exclusive Printable Content</Card.Title>
          <Card.Text>
            These print-ready versions of L2X lessons will make it easy to bring L2X to the classroom, kitchen table, or wherver your student does their learning. Stay tuned for updates!
          </Card.Text>
        </Card.Body>
      </Card>
    </div>

  );
};