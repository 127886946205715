import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted cookies
    const hasAcceptedCookies = localStorage.getItem('hasAcceptedCookies');
    if (!hasAcceptedCookies) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    // Save to localStorage that the user has accepted cookies
    localStorage.setItem('hasAcceptedCookies', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <p>We use cookies to support site functionality, analyze site usage, and marketing. By continuing to visit this site, you agree to our use of cookies.</p>
      <button onClick={handleAccept}>Accept</button>
    </div>
  );
};

export default CookieBanner;