import axios from 'axios';

export function testFunc() {
    console.log("inside testFunc");
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true
});

// URLs to exclude from retry
const excludeFromRetry = ['/logout', '/refresh'];

// Add a response interceptor.
instance.interceptors.response.use(
    (response) => {
      // If the response was successful, just return it.
      return response;
    },
    async (error) => {
        const originalRequest = error.config;
  
        console.log("originalRequest.url", originalRequest.url);
        // Check if the request URL is in the exclude list
        if (excludeFromRetry.includes(originalRequest.url)) {
            console.log("excluding from retry:", originalRequest.url);
            return Promise.reject(error);
        }

        console.debug("checking for 401 and _retry...");
        // Check if the error is a 401 and the request was with the retry header.
        if (error.response.status === 401 && !originalRequest._retry) {
            console.debug("retrying...");
            originalRequest._retry = true;

            // Attempt to refresh the token.
            try {
                console.debug("posting to /refresh");
                await instance.post('/refresh');
                console.debug("refreshed, retrying request");

                // Retry the original request.
                return instance(originalRequest);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        // If the error is due to other reasons, just throw it.
        return Promise.reject(error);
    },
  );

export const health = async () => {
    try {
        const response = await instance.get('/health');
        return response.data;
    } catch(error) {
        console.error('health failed:', error);
        throw error;
    }
}

export const authenticateEmail = async (email) => {
    try {
        const response = await instance.post('/authenticateemail', { email });
        return response.data;
    } catch(error) {
        console.error('authenticate failed:', error);
        throw error;
    }
};

export const authenticate = async (email, password) => {
    try {
        const response = await instance.post('/authenticate', { email, password });
        return response.data;
    } catch(error) {
        console.error('authenticate failed:', error);
        throw error;
    }
};

export const addHistory = async (result) => {
    try {
        const response = await instance.post('/history', { result }, {
            headers: {
              'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch(error) {
        console.error('history/add failed:', error);
        throw error;
    }
}

export const getHistory = async () => {
    try {
        const response = await instance.get('/history');
        return response.data;
    } catch(error) {
        console.error('history failed:', error);
        throw error;
    }
}

export const logout = async() => {
    try {
        console.log("POST to /logout");
        const response = await instance.post('/logout');
        console.log("logout success");
        return response.data;
    } catch(error) {
        console.error('logout failed:', error);
    }
}

export const refresh = async() => {
    try {
        console.log("POST to /refresh");
        const response = await instance.post('/refresh');
        console.log("refresh success");
        return response.data;
    } catch(error) {
        console.error('refresh failed:', error);
        throw error;
    }
}