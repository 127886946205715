import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';

const objectDictionary = {
  apple: '🍎',
  bear: '🧸',
  robot: '🤖',
  cupcake: '🧁',
  icecream: '🍦',
  none: '--'
}

const OfVisualization = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tableNumber, setTableNumber] = useState(parseInt(searchParams.get('table'), 10));
  const [tableObjectKey, setTableObjectKey] = useState(searchParams.get('object') || 'none');
  const [tableObject, setTableObject] = useState(objectDictionary[tableObjectKey]);

  const handleTableSelect = (eventKey) => {
    setTableNumber(eventKey);
    navigate(`/ofvisualization?table=${eventKey}&object=${tableObjectKey}`);
  };

  const handleTableObjectSelect = (eventKey) => {
    setTableObjectKey(eventKey);
    setTableObject(objectDictionary[eventKey]);
    navigate(`/ofvisualization?table=${tableNumber}&object=${eventKey}`);
  };

  const isValidTableNumber = tableNumber >= 1 && tableNumber <= 12;

  const tableStyle = {
    marginTop: '1em',
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  const highlightStyle = {
    backgroundColor: '#ADD8E6',
    paddingLeft: '0.5em',
    paddingRight: '0.25em',
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    textAlign: 'right',
  };

  const factorStyle = {
    textAlign: 'left',
    paddingLeft: '0.25em'
  }

  const getTimesTable = (tableNumber, startingNumber, length) => {
    return Array.from({ length }, (_, index) => index + startingNumber).map((number) => ({
      times: `${tableNumber} x`,
      number: number,
      of: `"${tableNumber} of"`,
      answer: tableNumber * number,
    }));
  }

  const getTimesTableRows = (timesTable) => {
    const timesTableRows = [];

    timesTable.map((entry, index) => timesTableRows.push(
      <React.Fragment key={index}>
        <tr>
          <td style={highlightStyle}>
            {entry.times}
          </td>
          <td style={factorStyle}>
          {entry.number}{tableObject === "--" ? null : tableObject}
          </td>
          <td style={factorStyle}>= {entry.answer}{tableObject === "--" ? null : tableObject}</td>
        </tr>
        <tr><td><div style={{ height: '1em' }}></div></td><td></td><td></td></tr>
        <tr>
          <td style={highlightStyle}>
            {entry.of}
          </td>
          <td style={factorStyle}>
            {entry.number}{tableObject === "--" ? null : tableObject}
          </td>
          <td style={factorStyle}>= {entry.answer}{tableObject === "--" ? null : tableObject}</td>
        </tr>
        <tr><td><div style={{ height: '2.5em' }}></div></td><td></td><td></td></tr>
      </React.Fragment>
    ));

    return timesTableRows;
  };

  const timesTableLeft = isValidTableNumber ? getTimesTable(tableNumber, 1, 6) : [];
  const timesTableLeftRows = getTimesTableRows(timesTableLeft);

  const timesTableRight = isValidTableNumber ? getTimesTable(tableNumber, 7, 6) : [];
  const timesTableRightRows = getTimesTableRows(timesTableRight);

  const factors = Array.from({ length: 12 });
  const tableNumberDropdownItems = factors.map((factor, factorIndex) => {
    return (
      <Dropdown.Item key={factorIndex + 1} eventKey={factorIndex + 1}>{factorIndex + 1}x as "{factorIndex + 1} of"</Dropdown.Item>
    );
  });

  const tableObjectDropdownItems = Object.entries(objectDictionary).map(([key, value]) => (
    <Dropdown.Item key={key} eventKey={key}>{value}</Dropdown.Item>
  ));

  return (
    <div className='toolsheetContent'>
      {isValidTableNumber ? (
        <div>
          <h2>The "{tableNumber} of" Times Table</h2>
          <h5 className='subtext'>Using "of" instead of "times"</h5>
          <div className='spacer'></div>
          <p>
            The word "times" and its symbol "x" can make multiplication feel mysterious to your student.
          </p>
          <p>
            This table creates a more verbally intuitive table by using "of" instead of "times"/"x".
          </p>
          <Container>
            <Row className="justify-content-center">
              <Col xs="auto">
                <Dropdown onSelect={handleTableSelect}>
                  <Dropdown.Toggle id="dropdown-basic">
                    {tableNumber}x as "{tableNumber} of"
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {tableNumberDropdownItems}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xs="auto">
                <Dropdown onSelect={handleTableObjectSelect} xs="auto">
                  <Dropdown.Toggle id="dropdown-basic">
                    {tableObject}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {tableObjectDropdownItems}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="justify-content-center">
              <Col md="auto">
                <table key="timesTableLeft" style={tableStyle}>
                  <tbody>
                    {timesTableLeftRows}
                  </tbody>
                </table>
              </Col>
              <Col md="auto">
                <table key="timesTableRight" style={tableStyle}>
                  <tbody>
                    {timesTableRightRows}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div>Please provide a valid table number between 1 and 12.</div>
      )}
    </div>
  );
};

export default OfVisualization;
