import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

export default function ThePyramid() {

  return (
    <div className="innerContent">
      <h2>L2X's Multiplication Pyramid</h2>
      
      <h5 className='subtext'>
        A structured journey to true mastery of multiplication
      </h5>

      <div className='spacer'></div>

      <p>Multiplication is typically taught in sequential order, with no sense of connection between the numbers.</p>
      <p>Instead, L2X's lessons are structured as a "pyramid" with each "layer" providing a foundation for the next.</p>
      <p>This turns multiplication into a meaningul journey where the student reaps the rewards of their efforts as they ascend the pyramid.</p>
      <p>The student achieves true mastery because they've learned to break every multiplication problem into simpler, more familiar components.</p>

      <div className='spacer'></div>

      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#baselayer">
        <Row>
          <Col sm={3}>
            <ListGroup>
              <ListGroup.Item action href="#apex">
                Apex Layer: 11x and 12x
              </ListGroup.Item>
              <ListGroup.Item action href="#thirdlayer">
                Third Layer: 7x and 8x
              </ListGroup.Item>
              <ListGroup.Item action href="#secondlayer">
                Second Layer: 3x, 4x, 6x, and 9x
              </ListGroup.Item>
              <ListGroup.Item action href="#baselayer">
                Base Layer: 1x, 10x, 2x, and 5x
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="#apex">
                <Card>
                  <Card.Header>Reaching the Top</Card.Header>
                  <Card.Body>
                    <Card.Title>The Apex Layer</Card.Title>
                    <Card.Text>
                      Completing the Pyramid, the Apex Layer is comprised of 11x and 12x, which can be easily understood as a combination of the base layer multiplication numbers!
                    </Card.Text>
                    <ListGroup>
                      <ListGroup.Item>11x: any number times 11 is the number times 10 plus the number.</ListGroup.Item>
                      <ListGroup.Item>12x: any number times 12 is the number times 10 plus 2 times the number.</ListGroup.Item>
                    </ListGroup>
                    <div className='spacer' />
                    <Button variant="primary" href="./lessons/apexlayer">Learn More</Button>
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="#thirdlayer">
                <Card>
                  <Card.Header>Combining Skills</Card.Header>
                  <Card.Body>
                    <Card.Title>The Third Layer</Card.Title>
                    <Card.Text>
                      The Third Layer of the L2X Multiplication Pyramid is comprised of 7x and 8x, both of which can be understood as a combination of the multiplication numbers from the base and second layer!
                    </Card.Text>
                    <ListGroup>
                      <ListGroup.Item>7x: any number times 7 is the number times 5 plus the number times 2.</ListGroup.Item>
                      <ListGroup.Item>8x: any number times 8 is the number times 4 times 2.</ListGroup.Item>
                    </ListGroup>
                    <div className='spacer' />
                    <Button variant="primary" href="./lessons/thirdlayer">Learn More</Button>
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="#secondlayer">
                <Card>
                  <Card.Header>Build on the Basics</Card.Header>
                  <Card.Body>
                    <Card.Title>The Second Layer</Card.Title>
                    <Card.Text>
                      The Second Layer of the L2X Multiplication Pyramid is comprised of 3x, 4x, 6x, and 9x. Your student will feel empowered when they see how the second layer can be understood as combinations of multiplication numbers from the base layer!
                    </Card.Text>
                    <ListGroup>
                      <ListGroup.Item>3x: any number times 3 is the number times 2 plus the number.</ListGroup.Item>
                      <ListGroup.Item>4x: any number times 4 is the number times 2 times 2.</ListGroup.Item>
                      <ListGroup.Item>6x: any number times 6 is the number times 5 plus the number.</ListGroup.Item>
                      <ListGroup.Item>9x: any number times 9 is the number times 10 minus the number.</ListGroup.Item>
                    </ListGroup>
                    <div className='spacer' />
                    <Button variant="primary" href="./lessons/secondlayer">Learn More</Button>
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="#baselayer">
                <Card>
                  <Card.Header>Establish the Foundation</Card.Header>
                  <Card.Body>
                    <Card.Title>The Base Layer</Card.Title>
                    <Card.Text>
                      The Base Layer of the L2X Multiplication Pyramid is comprised of 1s, 10s, 2s, and 5s. Becuase these are the most intuitive multiplication numbers, your student will gain confidence from grasping these numbers. Additionally, these multiplication numbers can be used as basic building blocks for understanding the remaining numbers. Yes, you read that right!
                    </Card.Text>
                    <ListGroup>
                      <ListGroup.Item>1x: any number times 1 is the number.</ListGroup.Item>
                      <ListGroup.Item>10x: any number times 10 is the number with a zero at the end of it.</ListGroup.Item>
                      <ListGroup.Item>2x: any number times 2 is the number added to itself.</ListGroup.Item>
                      <ListGroup.Item>5x: any number times 5 is half the number times 10.</ListGroup.Item>
                    </ListGroup>
                    <div className='spacer' />
                    <Button variant="primary" href="./lessons/baselayer">Learn More</Button>
                  </Card.Body>
                </Card>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div >);
}