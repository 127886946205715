import React from 'react';

import { Card, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';


export default function ThirdLayer() {
  return (
    <div className="innerContent">
      <h2>The Third Layer: 7x and 8x</h2>
      <h5 className='subtext'>
        Stretching and strengthening
      </h5>

      <div className='spacer'></div>

      <p>
        The third layer's numbers will call upon your student's strong command of skills and knowledge from the first two layers.
      </p>

      <section className="number-section">
        <h3>7x: A Combination of 5x and 2x</h3>
        <p>
          Any number multiplied by 7 is equal to the number multiplied by 5 plus the number multiplied by 2. For example, 7 x 6 is equivalent to 5 x 6, plus 2 x 6. From their work on the base layer, your student knows that 5 x 6 = 30 and 2 x 6 = 12. From their abilities with addition, your student can then determine that 30 + 12 = 42. Thus, 7 x 6 = 42.
        </p>
        <p>
          After your student posseses confidence in their understanding of 7x multiplication, work with them on memorization through repetition.
        </p>
        <Container>
          <Row>
            <Col sm>
              <Card>
                <Card.Header>7x, Teaching Tip #1</Card.Header>
                <Card.Body>
                  <Card.Title>Visualize 7x As 5x + 2x</Card.Title>
                  <Card.Text>
                    A table showing each 7x multiplication problem broken down into 5x plus 2x can help strengthen this concept in your student's mind.
                  </Card.Text>
                  <Button variant="primary" href="../decompose?table=7">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>7x, Teaching Tip #2</Card.Header>
                <Card.Body>
                  <Card.Title>The Word "Of" With Objects</Card.Title>
                  <Card.Text>
                    Sub in the word "of" in place of "times" and accompany this with a diagram of simple objects.
                  </Card.Text>
                  <Button variant="primary" href="../ofvisualization?table=7">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>7x, Teaching Tip #3</Card.Header>
                <Card.Body>
                  <Card.Title>Memorization Through Repetition</Card.Title>
                  <Card.Text>
                    Once your student is confident with their understanding of 7x multiplication, work with them on repition until they memorize their 7s.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="number-section">
        <h3>8x: A Combination of 5x and 3x</h3>
        <p>
          Any number multiplied by 8 is equal to the number multiplied by 5 plus the number multiplied by 3. For example, 8 x 6 is equivalent to 5 x 6, plus 3 x 6. From their work on the base layer, your student knows that 5 x 6 = 30 and 3 x 6 = 18. From their abilities with addition, your student can then determine that 30 + 18 = 48. Thus, 8 x 6 = 48.
        </p>
        <p>
          After your student posseses confidence in their understanding of 8x multiplication, work with them on memorization through repetition.
        </p>
        <Container>
          <Row>
            <Col sm>
              <Card>
                <Card.Header>8x, Teaching Tip #1</Card.Header>
                <Card.Body>
                  <Card.Title>Visualize 8x As 5x + 3x</Card.Title>
                  <Card.Text>
                    A table showing each 8x multiplication problem broken down into 5x plus 3x can help strengthen this concept in your student's mind.
                  </Card.Text>
                  <Button variant="primary" href="../decompose?table=8">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>8x, Teaching Tip #2</Card.Header>
                <Card.Body>
                  <Card.Title>The Word "Of" With Objects</Card.Title>
                  <Card.Text>
                    Sub in the word "of" in place of "times" and accompany this with a diagram of simple objects.
                  </Card.Text>
                  <Button variant="primary" href="../ofvisualization?table=8">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>8x, Teaching Tip #3</Card.Header>
                <Card.Body>
                  <Card.Title>Memorization Through Repetition</Card.Title>
                  <Card.Text>
                    Once your student is confident with their understanding of 8x multiplication, work with them on repition until they memorize their 8s.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <footer>
        <h3>Summary</h3>
        <p>
          Mastering the third layer requires strategic combinations and understanding of previously learned concepts. Once the 7s and 8s are grasped, learners are only a step away from mastering the entire pyramid.
        </p>
      </footer>
    </div>);
}