function MultiplicationTableElement({rowIndex, colIndex, hoveredCell, onCellHover, selectedCell, onCellSelect}) {
    const cellEnter = () => {
      onCellHover(rowIndex, colIndex);
    };
  
    const cellLeave = () => {
      onCellHover(null, null);
    };
  
    const cellSelect = () => {
      onCellSelect(rowIndex, colIndex);
    };
  
    let cellClass = "chartElement";
    let cellValue = rowIndex * colIndex;
    
    if (rowIndex === 0) {
      if (colIndex === 0) {
        cellClass = cellClass + " chartCornerElement";
        cellValue = "0";
      }
      else {
        cellClass = cellClass + " chartRowHeaderElement";
        cellValue = colIndex;
        if (selectedCell.col > colIndex) {
          cellClass = cellClass + " chartRowHeaderElementSelected";
        } else if (selectedCell.col === colIndex) {
            cellClass = cellClass + " chartRowHeaderElementSelectedMatch";
        }
      }
    } else {
      if (colIndex === 0) {
        cellClass = cellClass + " chartRowHeaderElement";
        cellValue = rowIndex;
        if (selectedCell.row > rowIndex) {
          cellClass = cellClass + " chartRowHeaderElementSelected";
        } else if (selectedCell.row === rowIndex) {
            cellClass = cellClass + " chartRowHeaderElementSelectedMatch";
        }
      } else {
        if (selectedCell.row === rowIndex && selectedCell.col === colIndex) {
          cellClass = cellClass + " chartElementSelected";
          cellValue = rowIndex * colIndex;
        } else {
          if (selectedCell.row >= rowIndex && selectedCell.col >= colIndex) {
            cellClass = cellClass + " chartElementSelectedRow";
          } 
        }
      }
    }  
    
    if (hoveredCell.row !== 0 || hoveredCell.col !== 0) {
        if (rowIndex === 0 && hoveredCell.col === colIndex && hoveredCell.col !== 0) {
            cellClass = cellClass + " chartElementHoveredSelect";
        } else if (colIndex === 0 && hoveredCell.row === rowIndex && hoveredCell.row !== 0) {
            cellClass = cellClass + " chartElementHoveredSelect";
        } else if (hoveredCell.row === rowIndex && hoveredCell.col === colIndex) {
            cellClass = cellClass + " chartElementHovered";
        }
    }
  
    return (
      <div className={cellClass} onMouseEnter={cellEnter} onMouseLeave={cellLeave} onClick={cellSelect}>{cellValue}</div> 
    );
  }
  
  function MultiplicationTableRow({rowIndex, tableSize, hoveredCell, onCellHover, selectedCell, onCellSelect}) {
    const colVals = Array.from({length: tableSize+1});
  
    const cells = colVals.map((col, colIndex) => {
      const cellKey = `${rowIndex}x${colIndex}`;
      return (
        <MultiplicationTableElement rowIndex={rowIndex} colIndex={colIndex} hoveredCell={hoveredCell} onCellHover={onCellHover} selectedCell={selectedCell} onCellSelect={onCellSelect} key={cellKey} />
      );
    });
  
    let rowClass = "chartRow";
    if (rowIndex === 0) {
      rowClass = rowClass + " chartHeaderRow";
    } 
  
    return (
      <div className="chartContainer">
        <div className={rowClass}>
          {cells}
        </div>
      </div>
    );
  }
  
  export default function MultiplicationTable({ tableSize, hoveredCell, handleHoveredCell, selectedCell, handleSelectedCell }) {  
    const rowVals = Array.from({length: tableSize+1});
  
    const rows = rowVals.map((row, rowIndex) => {
      return (
        <MultiplicationTableRow rowIndex={rowIndex} tableSize={tableSize} hoveredCell={hoveredCell} onCellHover={handleHoveredCell} selectedCell={selectedCell} onCellSelect={handleSelectedCell} key={`row ${rowIndex}`} />
      );
    });
  
    return (
      <div>
        {rows}
      </div>
    );
  }