import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import { useAuth } from './authprovider';

export default function Header() {
  const { authToken } = useAuth();

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">
          <img
            src="/logo512.png"
            width="40"
            height="40"
            className="d-inline-block align-top"
            alt="L2X"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
          <Nav className="mr-auto">

            {/* Mission Link */}
            <Nav.Link href="/mission">Mission</Nav.Link>

            <Nav.Link href="/dashboard">Dashboard</Nav.Link>

            {/* The Pyramid Dropdown */}
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link}>The Pyramid</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/thepyramid">Overview</Dropdown.Item>
                <Dropdown.Item href="/lessons/baselayer">Base Layer</Dropdown.Item>
                <Dropdown.Item href="/lessons/secondlayer">Second Layer</Dropdown.Item>
                <Dropdown.Item href="/lessons/thirdlayer">Third Layer</Dropdown.Item>
                <Dropdown.Item href="/lessons/apexlayer">Apex</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* Tools Dropdown */}
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link}>Tools</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/table">Table</Dropdown.Item>
                <Dropdown.Item href="/quiz">Quiz</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          </Nav>
          <Nav className="ml-auto">
            <Navbar.Text>
              {authToken ?
                <div>
                  {authToken.login.email} (<a href="/login">logout</a>)
                </div>
                : <a href="/login">Login</a>
              }
            </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}