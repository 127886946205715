import React, { createContext, useContext, useEffect, useState } from 'react';
import { refresh } from '../api/cocktaildb';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Retrieves the serialized (JSON string) from local storage
  // Rehydrates the object (JSON.parse)
  const getCurrentLogin = () => {
    const currentLoginJson = localStorage.getItem('currentLogin');
    let currentLogin = null;
    if (currentLoginJson) {
      currentLogin = JSON.parse(currentLoginJson);
    }
    return currentLogin;
  };

  // Serializes the object to JSON string
  // Persists in local storage
  const loginStore = (currentLogin) => {
    localStorage.setItem('currentLogin', JSON.stringify(currentLogin));
    setAuthToken(currentLogin);
  };

  const loginClear = () => {
    setAuthToken(null);
    localStorage.removeItem('currentLogin');
  };

  const isAuthenticated = async () => {
    const currentLogin = getCurrentLogin();
    if (currentLogin) {
      try {
        await refresh();
        return true;
      } catch (error) {
        console.log("isAuthenticated()->refresh() failed:", error);
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    console.log("authProvider useEffect;");
    const authenticateAndSetToken = async () => {
      if (await isAuthenticated()) {
        console.log("is authenticated");
        const token = getCurrentLogin();
        setAuthToken(token);
        console.log("authToken set:", token);
      } else {
        console.log("not authenticated");
      }
    };
  
    authenticateAndSetToken().then(() => {
      setIsLoading(false);
      console.log("isLoading now false");
    });
  }, []);  

  return (
    <AuthContext.Provider value={{ authToken, isLoading, loginStore, loginClear, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);