import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './ui/authprovider';
import ProtectedRoute from './ui/protectedroute';
import Header from "./ui/header";
import Login from "./ui/login";
import Selector from "./ui/selector";
import Home from './ui/home';
import Quiz from "./ui/quiz";
import "./App.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from "./ui/footer";
import CookieBanner from './ui/cookieBanner';
import Container from 'react-bootstrap/Container';
import ThePyramid from './ui/thepyramid';
import BaseLayer from './ui/lessons/baselayer';
import SecondLayer from './ui/lessons/secondlayer';
import ThirdLayer from './ui/lessons/thirdlayer';
import ApexLayer from './ui/lessons/apexlayer';
import Join from './ui/join';
import Dashboard from './ui/dashboard';
import Decompose from './ui/decompose';
import OfVisualization from './ui/ofvisualization';

function App() {
  return (
    <div className='App'>
      <AuthProvider>
        <CookieBanner />
        <Header />
        <div className="spacer" />
        <Container className="content">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/mission" element={<Home />} />
              <Route path="/join" element={<Join />} />
              <Route path="/thepyramid" element={<ThePyramid />} />
              <Route path="/lessons/baselayer" element={<BaseLayer />} />
              <Route path="/lessons/secondlayer" element={<SecondLayer />} />
              <Route path="/lessons/thirdlayer" element={<ThirdLayer />} />
              <Route path="/lessons/apexlayer" element={<ApexLayer />} />
              <Route path="/table" element={<Selector />} />
              <Route path="/quiz" element={<Quiz />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route path="/decompose" element={<Decompose />} />
              <Route path="/ofvisualization" element={<OfVisualization />} />
            </Routes>
          </BrowserRouter>
        </Container>
        <Footer />
      </AuthProvider>
    </div>
  );
}

export default App;