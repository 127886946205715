import { useState } from "react";
import MultiplicationPair from "./multiplicationPair";
import MultiplicationTable from "./multiplicationTable";

export default function Selector() {
  const [hoveredCell, setHoveredCell] = useState({row: null, col: null});
  const [selectedCell, setSelectedCell] = useState({row: null, col: null});
  
  function handleHoveredCell(hoveredRow, hoveredCol) {
    setHoveredCell({...hoveredCell, row: hoveredRow, col: hoveredCol});
  }

  function handleSelectedCell(row, col) {
    setSelectedCell({...selectedCell, row: row, col: col});
  }

  return (
      <>
          <h2>Table</h2>
          <MultiplicationPair selectedCell={selectedCell}/>
          <MultiplicationTable tableSize={12} hoveredCell={hoveredCell} handleHoveredCell={handleHoveredCell} selectedCell={selectedCell} handleSelectedCell={handleSelectedCell}/>
      </>
  );
}