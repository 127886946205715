import { useEffect, useState } from "react";
import { authenticateEmail, authenticate, logout } from "../api/cocktaildb";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
// import { getCurrentLogin, storeCurrentLogin, clearCurrentLogin } from "../api/utility";
import { useAuth } from "./authprovider";
import { Card, Form, Button, Container, InputGroup } from 'react-bootstrap';
import logo from '../resources/logo.png';

function Authenticate({ email, handleSubmitPassword, handlePasswordChanged, showAsSubComponent }) {
  if (showAsSubComponent) {
    return (
      <Form onSubmit={handleSubmitPassword}>
        <Form.Group controlId="otpInput">
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Enter the one-time passcode (OTP)"
              onChange={handlePasswordChanged}
            />
            <Button variant="primary" type="submit">
              Login
            </Button>
          </InputGroup>
        </Form.Group>
        <Form.Label className="inlineGuidance" style={{ marginTop: '10px' }}>
          OTP sent to {email}
        </Form.Label>
      </Form>
    );
  } else {
    return (
      <Form onSubmit={handleSubmitPassword}>
        <div className="spacer" />
        <Form.Group controlId="otpInput">
          <Form.Control
            type="text"
            placeholder="Enter the one-time passcode (OTP)"
            onChange={handlePasswordChanged}
          />
        </Form.Group>
        <div className="spacer" />
        <Form.Label className="inlineGuidance">Check {email} for an email from hello@learntomultiply.net</Form.Label>
        <div className="spacer" />
        <Form.Label className="inlineGuidance">The OTP will expire in a few minutes</Form.Label>
        <div className="spacer" />
        <Button variant="primary" type="submit" style={{ marginTop: '10px' }}>
          Login
        </Button>
      </Form>
    );
  }
}

function AuthenticateEmail({ email, handleSubmitEmail, handleEmailChanged, showAsSubComponent }) {
  const isValidEmail = (mail) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(mail);
  }

  if (showAsSubComponent) {
    // Return a more compressed layout
    return (
      <Form onSubmit={handleSubmitEmail}>
        <InputGroup>
          <Form.Control
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChanged}
            isValid={isValidEmail(email)}
            isInvalid={!!email && !isValidEmail(email)}
          />
          <Button variant="primary" type="submit">
            Login
          </Button>
          <Form.Control.Feedback type="invalid">
            Please enter a valid email.
          </Form.Control.Feedback>
        </InputGroup>
      </Form>
    );
  }
  else {
    // Return the original layout
    return (
      <Form onSubmit={handleSubmitEmail}>
        <Form.Group controlId="emailInput">
          <div className="spacer" />
          <Form.Control
            type="email"
            placeholder="Get started with your email"
            value={email}
            onChange={handleEmailChanged}
            isValid={isValidEmail(email)}
            isInvalid={!!email && !isValidEmail(email)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid email.
          </Form.Control.Feedback>
        </Form.Group>
        <div className="spacer" />
        <Button variant="primary" type="submit" style={{ marginTop: '10px' }}>
          Login
        </Button>
        <div className="spacer" />
      </Form>
    );
  }
}

export default function Login({ headerText, showAsSubComponent }) {
  const [phase, setPhase] = useState('unauthenticated');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { loginStore, loginClear } = useAuth();

  const location = useLocation();
  let navigate = useNavigate();

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    try {
      const currentLogin = await authenticate(email, password);
      loginStore(currentLogin);
      console.log('Authenticate succeeded:', currentLogin);
      if (!showAsSubComponent) {
        const { redirect } = queryString.parse(location.search);
        if (redirect) {
          console.log('Redirecting to:', redirect);
          navigate(redirect);
        } else {
          navigate('/');
        }
      }
    } catch (error) {
      console.error('Authentication failed:', error);
    }
  }

  const handlePasswordChanged = (e) => {
    setPassword(e.target.value);
  }

  const handleEmailChanged = (e) => {
    setEmail(e.target.value);
  }

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    try {
      const result = await authenticateEmail(email);
      console.log('Authenticate email succeeded:', result);
      console.log('Setting phase to:', result.nextStep);
      setPhase(result.nextStep);

    } catch (error) {
      console.error('Authentication failed:', error);
    }
  }

  useEffect(() => {
    logout();
    loginClear();
    // eslint-disable-next-line
  }, []);

  if (showAsSubComponent) {
    return (
      <Container className="d-flex justify-content-center">
        <Card style={{ width: '400px' }}>
          <Card.Body>
            {
              headerText ? <div style={{ marginBottom: '10px' }}>{headerText}</div> : <></>
            }

            {
              phase === "unauthenticated" ?
                <AuthenticateEmail email={email} handleSubmitEmail={handleSubmitEmail} handleEmailChanged={handleEmailChanged} showAsSubComponent={showAsSubComponent} />
                : phase === "authenticate" ?
                  <Authenticate email={email} handleSubmitPassword={handleSubmitPassword} handlePasswordChanged={handlePasswordChanged} showAsSubComponent={showAsSubComponent} /> :
                  <></>
            }
          </Card.Body>
        </Card>
      </Container>
    )
  } else {
    return (
      <Container className="d-flex justify-content-center">
        <Card style={{ width: '420px', marginTop: '30px' }}>
          <Card.Body>
            <Card.Img variant="top" src={logo} style={{ width: '75px', height: '75px', marginBottom: '10px' }} />
            <div className="spacer" />
            <div>
              Lessons, strategies, and tools for<br/>true mastery of multiplication
            </div>
            <div className="spacer" />
            <div>
              Created for awesome parents and incredible educators teaching multiplication to their students
            </div>
            <div className="spacer" />
            {
              phase === "unauthenticated" ?
                <AuthenticateEmail email={email} handleSubmitEmail={handleSubmitEmail} handleEmailChanged={handleEmailChanged} showAsSubComponent={showAsSubComponent} />
                : phase === "authenticate" ?
                  <Authenticate email={email} handleSubmitPassword={handleSubmitPassword} handlePasswordChanged={handlePasswordChanged} showAsSubComponent={showAsSubComponent} /> :
                  <></>
            }
          </Card.Body>
        </Card>
      </Container>
    );
  }
}