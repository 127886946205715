import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './authprovider'; 

const ProtectedRoute = ({ children }) => {
  const { authToken, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    console.log("AuthProvider loading...")
    return null;
  }

  console.log("AuthProvider load complete; authToken: ", authToken);

  if (!authToken) {
    // Redirect to the login page and include the current location in the redirect query parameter
    const redirectTo = encodeURIComponent(location.pathname + location.search);
    return <Navigate to={`/login?redirect=${redirectTo}`} replace />;
  }

  return children;
};

export default ProtectedRoute;
