import React from 'react';

import { Card, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

export default function SecondLayer() {
  return (
    <div className="innerContent">
      <h2>The Second Layer: 3x, 4x, 6x, and 9x</h2>
      <h5 className='subtext'>
        Building on intuition
      </h5>
      
      <div className='spacer'></div>

      <p>
        Encourage your student by showing them how the second layer multiplication numbers are actually comprised of numbers from the base layer.
      </p>

      <section className="number-section">
        <h3>3x: A Combination of 2x and 1x</h3>
        <p>
          Any number multiplied by 3 is equal to the number multiplied by 2 plus the number. For example, 3 x 7 is equivalent to 2 x 7, plus 7. From their work on the base layer, your student knows that 2 x 7 = 14. From their abilities with addition, your student can then determine that 14 + 7 = 21. Thus, 3 x 7 = 21.
        </p>
        <p>
          After your student posseses confidence in their understanding of 3x multiplication, work with them on memorization through repetition.
        </p>
        <Container>
          <Row>
            <Col sm>
              <Card>
                <Card.Header>3x, Teaching Tip #1</Card.Header>
                <Card.Body>
                  <Card.Title>Visualize 3x As 2x + 1x</Card.Title>
                  <Card.Text>
                    A table showing each 3x multiplication problem broken down into 2x plus 1x can help strengthen this concept in your student's mind.
                  </Card.Text>
                  <Button variant="primary" href="../decompose?table=3">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>3x, Teaching Tip #2</Card.Header>
                <Card.Body>
                  <Card.Title>The Word "Of" With Objects</Card.Title>
                  <Card.Text>
                    Sub in the word "of" in place of "times" and accompany this with a diagram of simple objects.
                  </Card.Text>
                  <Button variant="primary" href="../ofvisualization?table=3">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>3x, Teaching Tip #3</Card.Header>
                <Card.Body>
                  <Card.Title>Memorization Through Repetition</Card.Title>
                  <Card.Text>
                    Once your student is confident with their understanding of 3x multiplication, work with them on repition until they memorize their 3s.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="number-section">
        <h3>4x: Doubling Twice</h3>
        <p>
          Any number multiplied by 4 is equal to the number multiplied by 2 times 2 again. For example, 4 x 7 is equivalent to 2 x 7 times 2 again. Put another way, 4 x 7 is equivalent to the product of 2 x 7 added to itself. Your student already knows that 2 x 7 = 14. From their abilities with addition, your student can then determine that 14 + 14 = 28. Thus, 4 x 7 = 28.
        </p>
        <p>
          After your student posseses confidence in their understanding of 4x multiplication, work with them on memorization through repetition.
        </p>
        <Container>
          <Row>
            <Col sm>
              <Card>
                <Card.Header>4x, Teaching Tip #1</Card.Header>
                <Card.Body>
                  <Card.Title>Visualize 4x As 2x + 2x</Card.Title>
                  <Card.Text>
                    A diagram showing a 4x multiplication problem broken down into the number times 2 plus the number times 2 can help strengthen this concept in your student's mind.
                  </Card.Text>
                  <Button variant="primary" href="../decompose?table=4">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>4x, Teaching Tip #2</Card.Header>
                <Card.Body>
                  <Card.Title>The Word "Of" With Objects</Card.Title>
                  <Card.Text>
                    Sub in the word "of" in place of "times" and accompany this with a diagram of simple objects.
                  </Card.Text>
                  <Button variant="primary" href="../ofvisualization?table=4">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>4x, Teaching Tip #3</Card.Header>
                <Card.Body>
                  <Card.Title>Memorization Through Repetition</Card.Title>
                  <Card.Text>
                    Once your student is confident with their understanding of 4x multiplication, work with them on repition until they memorize their 4s.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="number-section">
        <h3>6x: A Combination of 5x and 1x</h3>
        <p>
          Any number multiplied by 6 is equal to the number multiplied by 5 plus the number.
        </p>
        <p>
          After your student posseses confidence in their understanding of 6x multiplication, work with them on memorization through repetition.
        </p>
        <Container>
          <Row>
            <Col sm>
              <Card>
                <Card.Header>6x, Teaching Tip #1</Card.Header>
                <Card.Body>
                  <Card.Title>Visualize 6x As 5x + 1x</Card.Title>
                  <Card.Text>
                    A diagram showing how a 6x multiplication problem breaks down into a 5x plus a 1x can help strengthen this concept in your student's mind.
                  </Card.Text>
                  <Button variant="primary" href="../decompose?table=6">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>6x, Teaching Tip #2</Card.Header>
                <Card.Body>
                  <Card.Title>The Word "Of" With Objects</Card.Title>
                  <Card.Text>
                    Sub in the word "of" in place of "times" and accompany this with a diagram of simple objects.
                  </Card.Text>
                  <Button variant="primary" href="../ofvisualization?table=6">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>6x, Teaching Tip #3</Card.Header>
                <Card.Body>
                  <Card.Title>Memorization Through Repetition</Card.Title>
                  <Card.Text>
                    Once your student is confident with their understanding of 6x multiplication, work with them on repition until they memorize their 6s.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="number-section">
        <h3>9x: A Combination of 10x and 1x</h3>
        <p>
          Any number multiplied by 9 is equal to the number multiplied by 10 minus the number.
        </p>
        <p>
          After your student posseses confidence in their understanding of 9x multiplication, work with them on memorization through repetition.
        </p>
        <Container>
          <Row>
            <Col sm>
              <Card>
                <Card.Header>9x, Teaching Tip #1</Card.Header>
                <Card.Body>
                  <Card.Title>Visualize 9x As 10x - 1x</Card.Title>
                  <Card.Text>
                    A diagram showing how a 9x multiplication problem breaks down into a 10x minus a 1x can help strengthen this concept in your student's mind.
                  </Card.Text>
                  <Button variant="primary" href="../decompose?table=9">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>9x, Teaching Tip #2</Card.Header>
                <Card.Body>
                  <Card.Title>The Word "Of" With Objects</Card.Title>
                  <Card.Text>
                    Sub in the word "of" in place of "times" and accompany this with a diagram of simple objects.
                  </Card.Text>
                  <Button variant="primary" href="../ofvisualization?table=9">Show Me</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card>
                <Card.Header>9x, Teaching Tip #3</Card.Header>
                <Card.Body>
                  <Card.Title>Memorization Through Repetition</Card.Title>
                  <Card.Text>
                    Once your student is confident with their understanding of 9x multiplication, work with them on repition until they memorize their 9s.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <footer>
        <h3>Summary</h3>
        <p>
          By mastering these numbers in the second layer, learners are well on their way to conquering the
          entire Multiplication Table.
        </p>
      </footer>
    </div>);
}