import React from 'react';
import { Button } from 'react-bootstrap';
import { useAuth } from './authprovider';

export default function Home() {
  const { authToken } = useAuth();

  return (
    <div className="innerContent">
      <div className="spacer" />

      <div className="spacer" />

      <h1>
        Break the Chains of Math Anxiety with L2X
      </h1>

      <h4 className='subtext'>
        Lessons, strategies, and tools to help your student master multiplication
      </h4>

      <div className="spacer" />

      {authToken ?
        <p>
          Hello, {authToken.login.email}!
        </p>
        :
        <Button
          variant="primary"
          href="./login"
        >
          Let's Go!
        </Button>
      }

      <div className="spacer" />

      <div className="spacer" />

      <h3>👋 Hey there, awesome parents and incredible educators!</h3>
      <p>
        We know that the journey to learning math can be extremely challenging. For both children and adults, the subject often feels like a mountain too steep to climb. And let's face it, multiplication is often the point in the journey that makes one question, "Can I even do math?"
      </p>

      <div className="spacer" />

      <h3>💪 Fear No More </h3>
      <p>
        Remember the sweaty palms and racing heart you felt when you were asked a multiplication question in front of the entire class? It's a pressure-packed moment that can make any student feel slow, inferior, and not good at math. Our goal is to change that, by making the multiplication tables a foundation for a lifetime of confidence in math rather than a moment for losing confidence.
      </p>

      <div className="spacer" />

      <h3>🌟 L2X: Your Partner in Multiplication Mastery</h3>
      <p>
        We created L2X with a singular mission: to break the chain of math anxiety and help your student soar with newfound confidence through thoughtful, encouraging lessons and tools designed for teaching the multiplication tables.
      </p>

      <div className="spacer" />

      <h3>🎯 What Makes L2X Special?</h3>
      <p>
        L2X's lessons and tools are designed to help you help your student gain mastery over the multiplication tables in the way that makes the most sense for them. For those who prefer a comprehensive approach, our learning roadmap breaks down the multiplication tables into step-by-step lessons that build progressively on one another. For those struggling with specific tables, our tips and tricks can unlock new understanding. And for those who want to build speed, we've created tools that help train multiplication table memorization.
      </p>

      <div className="spacer" />

      <h3>🚀 A Spirit of Partnership</h3>
      <p>
        Perhaps the most special thing about L2X is that it was created from a spirit of partnership with parents and teachers. Yes, there is a lot of thought and energy behind L2X's lessons and tools, but we know that we don't have all the answers. As your partner in this mission to break the chain of math anxiety, we will always seek your feedback so that L2X can get better and better.
      </p>
    </div>);
}